<template>
  <div class="basic">
    <el-form>
      <el-form-item label="Company">
        <el-select v-model="company">
          <el-option
            v-for="item in options.company.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-row :gutter="20">
        <el-col
          v-for="item in basic.fields"
          :key="item.id"
          :span="12"
        >
          <el-form-item :label="item.name || 'No label'">
            <el-input
              v-model="item.value"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Basic',

  data () {
    return {
      fieldName: '',
      filedValue: '',
      filedType: 'text',
      imageLink: '',
      showDialog: false,
      showAlert: false
    }
  },

  computed: {
    ...mapState(['basic']),
    ...mapState(['options']),
    office: {
      get () {
        return this.options.office.selected
      },
      set (v) {
        this.$store.dispatch('updateOptions', { office: { selected: v } })
      }
    },
    company: {
      get () {
        return this.options.company.selected
      },
      set (v) {
        this.$store.dispatch('updateOptions', { company: { selected: v } })
      }
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/variables';

.image-preview-wrapper {
  display: flex;
  margin-top: 20px;
  .image-preview {
    margin-right: 30px;
    width: 100px;
    height: 100px;
    background-color: #eee;
    border-radius: 100%;
    background-size: 100px;
    background-position: center center;
    background-size: cover;
    flex-shrink: 0;
    overflow: hidden;
    &__actions {
      width: 100%;
    }
  }
  .remove-image {
    padding: 10px;
    cursor: pointer;
    &:hover {
      i {
        color: #909399;
      }
    }
    i {
      color: #c0c4cc;
    }
  }

  .el-button {
    height: 40px;
  }
}
.remove-field {
  display: inline-block;
  cursor: pointer;
}
.edit {
  i {
    color: $color-primary;
  }
  &:hover {
    i {
      color: darken($color-primary, 20%);
    }
  }
}
.image-tips {
  border-bottom: 1px dashed;
  .el-popover__reference {
    cursor: pointer;
  }
}
</style>
